import React from "react"
import { useStores } from "./use-stores"

const AffiliatedSearchContext = React.createContext({})

export const AffiliatedSearchProvider = ({ children }) => {
  const { affiliated } = useStores()
  const { search } = affiliated
  const makeSearch = async ({
    category = "",
    searchParam = "",
    onSucces,
    onError,
    ...pagination
  }) => {
    await search({ searchParam, category, ...pagination })
      .then(onSucces)
      .catch(onError)
  }

  return (
    <AffiliatedSearchContext.Provider
      value={{
        makeSearch,
      }}
    >
      {children}
    </AffiliatedSearchContext.Provider>
  )
}
export const useAffiliatedSearch = () => {
  const context = React.useContext(AffiliatedSearchContext)

  if (!context) {
    throw new Error(
      "useAffiliatedSearch must be used within a AffiliatedSearchProvider!"
    )
  }

  return context
}

export default useAffiliatedSearch
