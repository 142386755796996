import React from "react"
import cx from "classnames"
import { Col, Row } from "antd"
import _get from "lodash/get"
import _find from "lodash/find"
import _size from "lodash/size"
import _isEmpty from "lodash/isEmpty"

import _toLower from "lodash/toLower"
import useAffiliatedSearch, {
  AffiliatedSearchProvider,
} from "@hooks/useAfiliatedSearch"
import SearchInput from "@components/SearchInput"
import Loading from "@components/Loading"
import { SearchContent } from "@components/searchModel/SearchContent"
import { AffiliatedType } from "@modules/Affiliated/store"

import { getResultText } from "@utils/strings"
import translate from "../../i18n"

import "./styles.less"

const options = [
  {
    value: "",
    label: "Todos",
  },
  {
    value: AffiliatedType.MODEL,
    label: "Modelos",
  },

  {
    value: AffiliatedType.PHOTOGRAFER,
    label: "Fotógrafos",
  },
]

const getCategoryByOptionLabel = (value) =>
  _get(
    _find(options, ({ label }) => _toLower(label) === _toLower(value)),
    ["value"]
  )

const DEFAULT_CATEGORY = "Todos"
const Creators = () => {
  const { makeSearch } = useAffiliatedSearch()
  const [isBottom, setIsBottom] = React.useState(false)

  const [dataContent, setDataContent] = React.useState([])
  const [totalData, setTotalData] = React.useState()
  const [showResultText, setShowResultText] = React.useState(false)
  const [queryParam, setQueryParam] = React.useState("")
  const [queryCategory, setQueryCategogry] = React.useState(DEFAULT_CATEGORY)
  const [isLoading, setIsLoading] = React.useState(false)
  const [pagination, setPagination] = React.useState({
    pageNumber: 0,
    pageSize: 20,
  })

  const handleClearSearch = () => {
    setQueryParam("")
    setQueryCategogry(DEFAULT_CATEGORY)
  }

  const handleSearch = async (query, isFirstSearch) => {
    setIsLoading(true)
    const category = _get(query, "category", DEFAULT_CATEGORY)
    const searchParam = _get(query, "searchParam")
    const cleanedCategory = getCategoryByOptionLabel(category)

    setQueryCategogry(category)
    const onSucces = (data) => {
      const content = _get(data, "data", [])
      const totalResult = _get(data, "total", [])
      setTotalData(totalResult)
      setDataContent(content)
      setShowResultText(isFirstSearch)
      setTimeout(() => {
        setIsLoading(false)
      }, 200)
    }
    const onError = (_) => {
      setDataContent([])
      setShowResultText(false)
    }
    await makeSearch({
      category: cleanedCategory,
      searchParam,
      onSucces,
      onError,
      ...pagination,
    })
  }

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop

    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight

    const innerHeight = window.innerHeight + 15

    setIsBottom(scrollTop + innerHeight >= scrollHeight)
  }

  React.useEffect(() => {
    handleSearch()

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const handleChangePagination = () =>
    setTimeout(() => {
      if (isBottom && !_isEmpty(dataContent)) {
        setPagination((prevState) => ({
          ...prevState,
          pageSize: prevState.pageSize + 12,
        }))
      }
    }, 1500)
  React.useEffect(() => {
    handleChangePagination()
  }, [isBottom])

  const resultText = getResultText(totalData)

  React.useEffect(() => {
    if (dataContent && totalData > pagination.pageSize) {
      const newShowResultText =
        (queryCategory === "all" || queryCategory === DEFAULT_CATEGORY) &&
        !queryParam
      handleSearch(
        { category: queryCategory, searchParam: queryParam },
        !newShowResultText
      )
    }
  }, [pagination])

  return (
    <section className="creators_wrapper">
      <h2 className="poppins title">Buscar criadores</h2>
      <SearchInput
        options={options}
        handleChangeCategory={(category) =>
          handleSearch({ category }, category !== "all" && !queryParam)
        }
        handleSearch={({ category, searchParam }) =>
          handleSearch(
            { category, searchParam },
            category !== "all" && !queryParam
          )
        }
        handleLoading={setIsLoading}
      />

      <section className="containerProfiles">
        {!isLoading && showResultText && Boolean(totalData) && (
          <section className="resultLengthContainer">
            <p>{resultText}</p>
          </section>
        )}

        <SearchContent
          hideSeeAll
          hideLoading
          isLoading={isLoading}
          customIcon="EmptyStateCreators"
          dataContent={dataContent}
          notFoundClassName="notFoundClassName"
          notFoundText={translate("profileNotFound")}
          setSearchValue={setQueryParam}
          closeSearch={handleClearSearch}
          handleViewAllCreatorsProfile={handleClearSearch}
          setDataContent={setDataContent}
        />
        {isLoading && <Loading />}
      </section>
    </section>
  )
}

const _WithProvider = () => (
  <AffiliatedSearchProvider>
    <Creators />
  </AffiliatedSearchProvider>
)
export default _WithProvider
