import React from "react"

import TranslateWrapper from "@components/TranslateWrapper"
import Creators from "@modules/Creators"
import PageCenter from "@components/PageCenter"

import Helmet from "react-helmet"

const CreatorsPage = () => (
  <TranslateWrapper>
    <Helmet>
      <title>Criadores - Anonimatta</title>
    </Helmet>
    <main>
      <PageCenter>
        <Creators />
      </PageCenter>
    </main>
  </TranslateWrapper>
)

export default CreatorsPage
